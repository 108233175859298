import axios from "axios";

export const getAccessToken = async () => {
  const clientId = "bdb514f5-3525-402e-9e27-abc65d156771";
  const clientSecret = "uLK8Q~S.e655~hkEjNWmebIk~hU1MIkcYwhFEcC2";
  const tenantId = "eb040f45-2b27-4e70-93fd-2bae4f0ac4cf";

  const tokenUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;

  const data = new URLSearchParams({
    grant_type: "client_credentials",
    client_id: clientId,
    client_secret: clientSecret,
    scope: "https://orgdb56556c.crm4.dynamics.com/.default",
  });

  try {
    const response = await axios.post(tokenUrl, data);
    return response.data.access_token;
  } catch (error) {
    console.error("Error fetching token", error);
  }
};