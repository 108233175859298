import * as React from "react";
import logo from "../../assets/img/logo couleur.png";

function SvgComponent(props) {
  return (
    <img src={logo} width={200} height={30} {...props} alt={"FST SB Logo"} />
  );
}

export default SvgComponent;
