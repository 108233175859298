import yosri from "../../../assets/img/2024-2025/sb/Yosri Esaalah.jpg";
import ghassen from "../../../assets/img/2023-2024/sb/ghassen.jpg";
import dorra from "../../../assets/img/2024-2025/sb/Dorra Mkaddem.jpg";
import mariem from "../../../assets/img/2024-2025/sb/Mariem gioui.jpg";
import emna from "../../../assets/img/2024-2025/sb/Emna_Filali.jpg";
import hyba from "../../../assets/img/2024-2025/sb/Hyba Benlakhder .jpg";
import salim from "../../../assets/img/2024-2025/sb/salim SEHLI.jpg";

const officers = [
    {
        "name": "Yosri Essalah",
        "position": "Chair",
        "image": yosri,
        "chapter": "sb"
    },
    {
        "name": "Ghassen Bougacha",
        "position": "Vice Chair",
        "image": ghassen,
        "chapter": "sb"
    },
    {
        "name": "Dorra Mkaddem",
        "position": "Secretary",
        "image": dorra,
        "chapter": "sb"
    },
    {
        "name": "Mariem Gioui",
        "position": "Treasurer",
        "image": mariem,
        "chapter": "sb"
    },
    {
        "name": "Emna Filali",
        "position": "Webmaster",
        "image": emna,
        "chapter": "sb"
    },
    {
        "name": "Hiba Ben Lakhdher",
        "position": "Coordinator",
        "image": hyba,
        "chapter": "sb"
    },
    {
        "name": "Salim Sehli",
        "position": "Human Resources Manager",
        "image": salim,
        "chapter": "sb"
    },
]

export default officers