import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Team from "../components/Sections/Team";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import React from "react";

export default function Landing() {
  return (
    <>
      <TopNavbar chapter={"mtts"} />
      <Header chapter={"mtts"} />
      <Services chapter={"mtts"} />
      <Projects chapter={"mtts"} />
      <Blog chapter={"mtts"} />
      <Team chapter={"mtts"} />
      <Contact chapter={"mtts"} />
      <Footer chapter={"mtts"} />
    </>
  );
}
